<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Caja</v-card-title>
        <DataTableCash :rol="rolUserLogged"></DataTableCash>
      </v-card>
    </v-col>
    <impago-modal ref="impagoModal"></impago-modal>
  </v-row>
</template>

<script>
import DataTableCash from './datatables/DataTableCash.vue'
import ImpagoModal from '../dashboard/ModalImpago.vue'

export default {
  components: {
    ImpagoModal,
    DataTableCash,

  },
  data: () => ({
    rolUserLogged: null,
  }),
  created() {
    this.rolUserLogged = this.$store.getters.usuario.user_rol
  },
  mounted() {
    // if(this.rolUserLogged == 'Encargado' || this.rolUserLogged == 'Administrador' || this.rolUserLogged == 'Gerente'){
    //   this.$refs.impagoModal.openDialog()

    // }
  }
}
</script>
