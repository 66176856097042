<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <!-- <v-card-title>
          <span class="text-h5">Deuda Impaga</span>
        </v-card-title> -->
  
        <v-card-text>
          <v-container>
            
    <v-alert
    color="error"
      outlined
      type="error"
      prominent
      border="left"
    >
    Recuerde abonar el saldo hasta el 01/06/2023 o se limitaran las funciones del sistema    
    </v-alert>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
  
          <v-btn
            color="error"
            depressed
            @click="closeDialog()"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <script>
  import {
    mdiMapMarkerRadiusOutline,
  } from '@mdi/js'
  export default {
    components: {  },
    data() {
      return {
        dialog: false,
      }
    },
    methods: {
  
      openDialog() {
        this.dialog = true
      },
      closeDialog() {
        this.dialog = false
      },
    },
  }
  </script>
  